import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { TableActionConfig } from '../../../../core/modals/table.modal';

@Component({
  selector: 'app-table-item-action-dropdown',
  standalone: true,
  imports: [],
  templateUrl: './table-item-action-dropdown.component.html',
  styleUrls: ['./table-item-action-dropdown.component.scss'],
})
export class TableItemActionDropdownComponent {
  @Input() item: any;
  @Output() editRequest = new EventEmitter<any>();
  @Output() deactivateRequest = new EventEmitter<number>();
  @Output() activateRequest = new EventEmitter<number>();
  @Output() deleteRequest = new EventEmitter<number>();
  @Output() downloadRequest = new EventEmitter<any>();

  @Input() config?: TableActionConfig;

  constructor() {}

  get isEditable(): boolean {
    return this.config?.isEditable ?? true;
  }

  get isDeletable(): boolean {
    return this.config?.isDeletable ?? true;
  }

  get isActivatable(): boolean {
    return this.config?.isActivatable ?? true;
  }

  get isDownloadable(): boolean {
    return this.config?.isDownloadable ? true : false;
  }

  openEditModal(item: any) {
    if (this.isEditable) {
      this.editRequest.emit(item);
    }
  }

  requestActivateItem(id: number) {
    this.activateRequest.emit(id);
  }

  requestDeactivateItem(id: number) {
    this.deactivateRequest.emit(id);
  }

  requestDeleteItem(id: number) {
    this.deleteRequest.emit(id);
  }

  requestDownloadItem(item: any) {
    this.downloadRequest.emit(item);
  }
}
