<button type="button" class="action-button"  data-bs-toggle="dropdown" [id]="'action'+item.id" >
  <img src="assets/images/icons/icon-more.svg" />
</button>
<ul class="dropdown-menu" [attr.aria-labelledby]="'action'+item.id">
  @if(isEditable){
    <li>
      <a class="dropdown-item" (click)="openEditModal(item)">
        <img src="assets/images/icons/icon-edit.svg" /> Edit
      </a>
    </li>
  }
  @if(isActivatable){
    @if(item.status=='active'){
      <li>
        <a class="dropdown-item" (click)="requestDeactivateItem(item.id)" >
            <img src="assets/images/icons/icon-template.svg" /> Deactivate
          </a>
      </li>
    }
    @else if (item.status=='inactive') {
      <li>
        <a class="dropdown-item"  (click)="requestActivateItem(item.id)">
          <img src="assets/images/icons/icon-template.svg" /> Activate
        </a>
      </li>
    }
  }
  @if(isDeletable){
    <li>
      <a class="dropdown-item" (click)="requestDeleteItem(item.id)" >
        <img src="assets/images/icons/icon-delete.svg" /> Delete
      </a>
    </li>
  }
  @if(isDownloadable){
    <li>
      <a class="dropdown-item" (click)="requestDownloadItem(item)" >
        <img src="assets/images/icons/icon-download.svg" /> Download
      </a>
    </li>
  }
</ul>
